#about {
    margin: 7rem 0;
    z-index: 9;
    width: 100%;
}

#about .container h2 {
    text-align: center;
    margin-bottom: 5rem;
    font-family: "Zilla Slab";
    font-size: 2.3rem;
}

#about .row .card-info {
    background-color: #fff;
    padding: 40px 30px;
    /* border: 0.5px solid lightgrey; */
    border-radius: 7px;
}

#about .row .card-info p {
    font-family: "Zilla Slab";
    font-size: 1.2rem;
}

#about .row .card-info p:nth-of-type(2) {
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 1.1rem;
}


#about .row .about-img-container {
    position: relative;
    /* left: 10%; */
    /* max-width: 100%; */
}

#about .row .about-img-container img:nth-of-type(1) {
    position: absolute;
    top: -10px;
    width: 190px;
    left: 15%;
    animation: rotate 4s infinite linear forwards;
    margin: 0 auto;
}


@keyframes rotate {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(-360deg);
    }
}


#about .row .about-img-container img:nth-of-type(2) {
    z-index: 9;
    width: 190px;
    position: absolute;
    left: 15%;
}


#about .container .service-btn {
    text-align: center;
    margin-top: 14rem;
}


#about .container .service-btn p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-family: "Zilla Slab";
}

#about .container .service-btn .button {
    text-decoration: none;
    border: 0;
    font-size: 13px;
    padding: 14px 36px;
    font-family: "Montserrat", sans-serif;
    transition: color 300ms ease-in-out;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    font-weight: 800;
    box-shadow: 0 4px 10px rgba(0,0,0,0.08), 0 -2px 10px rgba(0,0,0,0.08);
    z-index: 1;
    position: relative;
    color: #ff3333;
}

#about .container .service-btn .button:hover {
    color: #fff;
}

#about .container .service-btn .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-color: #ff3333;
    box-sizing: border-box;

    transition: transform 300ms ease-in-out;
    transform: scaleX(0);
    transform-origin: right
}

#about .container .service-btn .button:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}



@media screen and (max-width: 768px) {
    #about .row {
        display: flex;
        flex-direction: column-reverse;
    }

    #about .row .card-info {
        margin-top: 20rem;
    }

    #about .row .about-img-container {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 420px) {
    #about .row .about-img-container img:nth-of-type(1) {
        width: 150px;
        left: 20%;
    }

    #about .row .about-img-container img:nth-of-type(2) {
        width: 150px;
        left: 20%;
    }
}