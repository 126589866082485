.card {
    padding: 40px 30px;
    border: none;
    outline-width: 0;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    transition: transform 200ms ease-in-out;
}

.card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1) !important;
}

.card img {
    width: 120px;
    margin: 0 auto;
}

.card h3 {
    text-align: center;
    margin: 30px 0;
    font-size: 1.6rem;
}

.card p {
    font-family: "Montserrat";
    font-size: 1rem;
}

.card img:nth-of-type(4) {
    transform: rotateX(45deg);
}

