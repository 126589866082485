#technology {
    margin: 12rem 0;
    background-color: rgba(143, 240, 154, 0.3);
    padding: 8rem 0;
}

#technology .container .row img {
    width: 100%;
    border-radius: 10px;
} 

#technology .container .tech-heading {
    margin-bottom: 7rem;
}

#technology .container .tech-heading h3 {
    font-family: "Montserrat";
    margin-bottom: 1.7rem;
}

#technology .container .tech-heading h2 {
    font-family: "Zilla Slab";
    font-size: 2.3rem;
    position: relative;
    width: 210px;
}

#technology .container .tech-heading h2::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    left: -15px;
    background-color: rgba(93, 199, 22, 0.788);
    z-index: -1;
    transition: width 500ms 0.1s;
}

#technology .container .tech-heading h2:hover::before {
    width: 100%;
}


#technology .container .row .tech-content p {
    font-family: "Montserrat";
    font-size: 1.25rem;
}

#technology .container .row .tech-content li {
    list-style-type: circle;
    font-family: "Montserrat";
}