
#notfound {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

#notfound img {
    width: 300px;
    margin: auto;
    max-width: 100%;
}

#notfound p {
    font-size: 1.5rem;
}

.home__link {
    text-decoration: none;
    color: crimson;
    font-weight: 500;
    font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
    #notfound img {
        width: 260px;
    }
}