#contact {
    margin: 7rem 0;
    z-index: 9;
    margin-bottom: 350px;
}

.flash__message {
    top: 0;
    margin-bottom: 40px;
    /* background-color: #D4EDDA; */
    /* border: 0.5px solid lightgray; */
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 450px;
    margin: 0 auto;
    max-width: 100%;
    margin-bottom: 40px;
    /* color: green; */
    padding: 50px 20px;
    text-align: center;
    border-radius: 5px;
    display: none;
    
    animation: transform 2s ease;    
}

@keyframes transform {
    0% {
        transform: translateY(-100px);
    }

    100% {
        transform: translateY(0px);
    }

}

.flash__message p {
    margin: 0;
    font-size: 1.2rem;
}

.flash__message p:nth-of-type(1) {
    font-weight: 600;
    font-size: 1.3rem;
} 

.flash-message {
    display: block;
}

#contact .container .contact__heading {
    text-align: center;
    font-size: 2.5rem;
    font-family: "Zilla Slab";
    margin-bottom: 6rem;
}

#contact .contact-left {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

#contact .contact-left h3 {
    font-size: 2.2rem;
    margin-bottom: 2rem;
}

#contact .contact-left li {
    list-style-type: none;
    margin-left: -2rem;
    padding: 0.4rem 0;
    font-size: 1.2rem;
    font-family: "Montserrat";
    font-weight: bold;
}


#contact .contact-right h2 {
    font-family: "Montserrat";
    font-size: 2.4rem;
    font-weight: bolder;
    margin-bottom: 4rem;
    color: #ff3333;
}


#contact .contact-right h4 {
    font-size: 1.4rem;
    margin-bottom: 2rem;
}


#contact .contact-right form input {
    width: 18rem;
    max-width: 100%;
    padding: 10px 25px;
    margin-bottom: 2.5rem;
    font-family: "Zilla Slab";
    font-size: 1.2rem;
}

#contact .contact-right form textarea {
    width: 33rem;
    max-width: 100%;
    height: 10rem;
    padding: 10px 20px;
    margin-bottom: 1rem;
    font-family: "Zilla Slab";
    font-size: 1.2rem;
}

#contact .contact-right form input, textarea {
    border: none;
    border: 2px solid #E6E6E6;
    border-radius: 5px;
    background-color: #E6E6E6;
}

#contact .contact-right form input:focus, textarea:focus {
    /* border: none; */
    outline-width: 0;
    border: 2px solid crimson;
}

#contact .contact-right form input::placeholder, textarea::placeholder {
    font-family: "Zilla Slab";
    font-size: 1.2rem;
}

#contact .contact-right form .contact__button {
    border: none;
    outline-width: 0;
    background: none;
    padding: 10px 30px;
    background-color: #FF3333;
    color: #fff;
    letter-spacing: 1.5px;
    font-size: 0.9rem;
    font-weight: 700;
    font-family: "Montserrat";
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.13);
    margin-bottom: 5rem;
}

#contact .contact-right form .contact__button:hover {
    background-color: #d31d1d;
}

@media screen and (max-width: 768px) {

    #contact .contact-right h2 {
        font-size: 2rem;
        margin-top: 4rem;
    }
}