#getInTouch {
    background-color: #ff3333;
    text-align: center;
    padding: 17rem 0;
    margin-bottom: 350px;
}


#getInTouch .container h1 {
    color: rgb(240, 239, 239);
    margin-bottom: 3rem;
}

#getInTouch .container .contact-btn {
    text-decoration: none;
    border: 0;
    font-size: 13px;
    padding: 14px 36px;
    font-family: "Montserrat", sans-serif;
    transition: color 300ms ease-in-out;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 700;
    box-shadow: 0 3px 8px rgba(0,0,0,0.14);
    z-index: 1;
    position: relative;
    color: #fff;
}

#getInTouch .container .contact-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-color: #fff;
    box-sizing: border-box;

    transition: transform 300ms ease-in-out;
    transform: scaleX(0);
    transform-origin: right;
}

#getInTouch .container .contact-btn:hover {
    color: crimson;
}

#getInTouch .container .contact-btn:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}


/* Media Queries */
@media screen and (max-width: 441px) {

    #getInTouch {
        padding: 12rem 0;
    }
}