.App {
    background: #f9f9f9;
}

::-webkit-scrollbar {
  /* display: none; */
  width: 5px;
}

::-webkit-scrollbar-track {
    background: #cacaca;
}
::-webkit-scrollbar-thumb {
    background: #797979;
    border-radius: 5px;
}

.App {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: 5px;  /* Firefox */
}

.App .loader {
    position: fixed;
    background-color: #f9f9f9;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App .loader img {
    width: 90px;
    animation: opacity 1.5s ease-in-out forwards;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.color__slide {
    height: 100%;
    width: 100%;
    background-color: #FF904B;
    position: fixed;
    top: 0;
    z-index: 99;
}