#footer {
    background-color: #333333;
    padding: 3rem 0 2rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: -1;
    overflow-y: hidden;
}

#footer .footer-elements li {
    list-style-type: none;
    padding: 0.6rem 0;
}

#footer .footer-elements .footer__links {
    display: flex;
    gap: 7rem;
    margin-top: 3rem;
}

#footer .footer-elements .footer__link {
    text-decoration: none;
    color: #fff;
    font-family: "Zilla Slab";
    font-size: 1.1rem;
    letter-spacing: 0.8px;
    position: relative;
}

#footer .footer-elements .footer__link::before {
    content: "";
    position: absolute;
    height: 1.7px;
    background-color: rgb(114, 112, 112);
    width: 100%;
    left: 0;
    bottom: -4px;
}

#footer .footer-elements .footer__link::after {
    content: "";
    position: absolute;
    height: 1.7px;
    background-color: #fff;
    width: 100%;
    left: 0;
    bottom: -4px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 400ms ease;
    cursor: pointer;
}

#footer .footer-elements .footer__link:hover::after {
    /* width: 100%; */
    transform-origin: left;
    transform: scaleX(1);
}

#footer hr {
    background-color: lightgrey;
    height: 2px;
    margin: 1rem 0;
    margin-bottom: 2rem;
}

#footer .footer-bottom {
    /* margin-top: 3rem; */
    display: flex;
    justify-content: space-between;
    color: #fff;
    letter-spacing: 0.5px;
    color: lightgrey;
}


/* Media Queries */
@media screen and (max-width: 768px) {
    #footer .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    #footer hr {
        margin: 1rem 0;
        margin-bottom: 1.5rem;
    }
}



@media screen and (max-width: 441px) {

    #footer .footer-bottom p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 375px) {
    #footer .footer-elements .footer__links {
        display: flex;
        gap: 3rem;
    }
    #footer .footer-bottom p {
        font-size: 0.8rem;
    }
}