#project {
    margin-top: 200px;
    overflow-x: hidden;
    /* display: none; */
    z-index: 99;
    margin-bottom: 350px;
}

li {
    list-style-type: none;
}

#project .project_name {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 3rem;
}

#project .project_head {
    font-family: "Zilla Slab";
    font-weight: 500;
    color: grey;
    font-size: 2.4rem;
}   

#project .project_banner {
    margin-top: 7rem;
}

#project .project_banner img {
    width: 100%;
} 

#project .introduction {
    margin-top: 7rem;
}

#project .project_intro {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

#project .project_desc {
    font-family: "Zilla Slab";
    font-size: 1.3rem;
    color: grey;
    width: 700px;
    max-width: 100%;
}


#project-main {
    margin-top: 7rem;
}

#project-main .role_section {
    margin-top: 6rem;
}

#project-main .role_section h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

#project-main .role_section p {
    font-size: 1.1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: grey;
}

#project-main .role_section .role {
    display: flex;
    margin-top: 2rem;
}

#project-main .role_section .role li {
    font-family: "Zilla Slab";
    font-size: 1.2rem;
}

#project-main .process {
    margin-top: 10rem;
}

#project-main .process .process_main_head {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: grey;
    margin-bottom: 7rem;
}

#project-main .process .process_state {
    margin-bottom: 9rem;
}

#project-main .process .process_state .process_heading {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #000000;
    position: relative;
}

#project-main .process .process_state .process_heading::before {
    font-size: 0.9rem;
    position: absolute;
    top: 5px;
    left: -30px;
    font-weight: 700;
    font-family: "Montserrat";
    color: grey;
}

#project-main .process .process_state .process_desc {
    font-family: "Zilla Slab", serif;
    font-size: 1.4rem;
    color: grey;
    margin-top: 1rem;
}

#project-main .process .process_strategy .process_heading::before {
    content: "01";
}

#project-main .process .process_design .process_heading::before {
    content: "02";
}

#project-main .process .process_implementation .process_heading::before {
    content: "03";
}

#design_screens {
    background: #D9F6DD;
    padding: 7rem 0;
}

#design_screens .design_screen_each {
    margin-bottom: 4rem;
}

#design_screens .design_screen_each .design_screen_heading {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 1rem;
}

#design_screens .design_screen_each img {
    width: 70%;
    display: block;
    margin: 0 auto;
}

#design_screens .design_screen_each .login_reg_flex {
    display: flex;
    justify-content: center;
}

#project-main .process .tech_stack {
    margin-bottom: 5rem;
}

#project-main .process .tech_stack .teck_stack_head {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 3rem;
    text-align: center;
}

#project-main .process .tech_stack img {
    width: 500px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
}




/* Media Queries */

@media screen and (max-width: 551px) {

    #project .project_name {
        font-size: 2rem;
    }

    #project .project_head {
        font-family: "Zilla Slab";
        font-weight: 500;
        color: grey;
        font-size: 1.7rem;
    } 

    #project-main .process .process_state .process_desc {
        font-size: 1.12rem;
    }

    
    #design_screens .design_screen_each img {
        width: 100%;
    }

    #design_screens .design_screen_each .login_reg_flex {
        flex-direction: column;
        gap: 2rem;
    }
}