

.header_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px 30px;    */
}

.header_logo {
    display: flex;
    gap: 10px;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 20px;
    left: 20px;
}

.openBtn {
    cursor: pointer;
    position: fixed;
    right: 25px;
    top: 25px;
    z-index: 999;
}



/* Menu Style */
.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  overflow-y: hidden;
  transition: 0.5s;
}


.overlay-content {
  position: relative;
  top: 25%;
  width: 200px;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
}

.overlay .link {
  padding: 8px;
  font-family: "Zilla Slab";
  text-decoration: none;
  font-size: 3rem;
  font-weight: 600;
  color: #808080;
  display: block;
  transition: 0.3s;
  letter-spacing: 2px;
}

.overlay .link:hover {
    color: #fff;
    padding-left: 35px;
    transition-duration: 300ms;
    position: relative;
}

.overlay .link:hover::before {
    position: absolute;
    font-family: "Montserrat", sans-serif;
    color: white;
    top: 25px;
    left: -5px;
    font-size: 15px;
    z-index: -1;
} 

.overlay .link1:hover::before {
    content: "01";
}

.overlay .link2:hover::before {
    content: "02";
}

.overlay .link3:hover::before {
    content: "03";
}

.overlay .link4:hover::before {
    content: "04";
}

.overlay .link5:hover::before {
  content: "05";
}

.overlay .closebtn {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
}


.magnet {
  transition: all 0.5s ease;
}

@media screen and (max-width: 441px) {
    .overlay .link {
      font-size: 2rem;
    } 

    .header_logo {
      top: 17px;
      left: 17px;
    }

    .openBtn {
      top: 20px;
      right: 17px;
    }
}

