#strategy {
    overflow-x: hidden;
    margin-top: 200px;
    /* background-color: rgba(0, 238, 255, 0.1); */
    background-color: rgba(255, 235, 205, 0.7);
}

#strategy .container h2 {
    margin-top: 3rem;
    font-family: "Zilla Slab";
    margin-bottom: 4rem;
    font-size: 2.3rem;  
    width: 220px;
    z-index: 9;
    position: relative;
    transition: color 500ms 0.1s;
}

#strategy .container h2::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    left: -15px;
    /* background-color: rgb(211, 21, 21); */
    background-color: rgb(211, 139, 31);
    z-index: -1;
    transition: width 500ms 0.1s;
}

#strategy .container h2:hover::before {
    width: 100%;
}

#strategy .heading:hover {
    /* color: #fff; */
}


#strategy .row {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 170px;
}

#strategy .row img {
    width: 100%;
    border-radius: 10px;
}

#strategy .row .cards {
    background-color: #fff;
    padding: 40px 30px;
    margin-bottom: 10px;    
    border: 0.5px solid lightgrey;
    border-radius: 7px;
    /* box-shadow: 0 8px 20px rgba(0,0,0,0.15); */
}

#strategy .row .strategy h2 {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 2rem;
}


#strategy .row .strategy h3 {
    color: grey;
}

#strategy .row .strategy .main-strategy-work:nth-of-type(1) {
    display: flex;
    gap: 2.5rem;
    margin-top: 2rem;
    justify-content: center;
}

#strategy .row .strategy .main-strategy-work li {
    list-style-type: circle;
    font-family: "Montserrat"; 
}


#strategy .row .main-strategy-work .design p {
    font-family: "Montserrat";
    font-size: 1.1rem;
    padding: 20px;
}




/* Media Queries */

@media screen and (max-width: 1360px) {

    #strategy .row .cards {
        padding: 20px 20px;
    }

    #strategy .row .main-strategy-work .design p {
        padding: 10px;
    }

}

@media screen and (max-width: 1024px) {
    #strategy .row .strategy h2 {
        font-size: 1.4rem;
        margin-bottom: 1.3rem;
    }
    
    #strategy .row .strategy h3 {
        font-size: 1.4rem;
    }
}


@media screen and (max-width: 768px) {
    #strategy .row .strategy .main-strategy-work {
        flex-direction: column;
        gap: 1.8rem;
    }

    #strategy .heading {
        font-size: 1.8rem; 
    }

    #strategy .heading:hover::before {
        width: 180px;
    }

    #strategy .row img {
        margin-bottom: 2rem;
    }

    #strategy .row {
        margin-bottom: 100px;
    }

    #strategy .row .main-strategy-work .design p {
        font-size: 1rem;
    }
}