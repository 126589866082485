
.loader {
    position: fixed;
    background-color: #f9f9f9;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}  

.loader img {
    width: 90px;
    animation: opacity 0.6s ease forwards;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.color__slide {
    height: 100%;
    width: 100%;
    background-color: #FF904B;
    position: fixed;
    top: 0;
    z-index: 99;
}