
/* Banner */


.banner {
    /* height: 100vh; */
    overflow-y: hidden;
    /* margin-top: 4rem; */
    overflow-x: hidden;
}

.banner .row {
    padding: 20px; 
    margin-top: 8rem;
    /* margin-bottom: 4rem; */
}

#stage-left .stage-caption p {
    font-family: "Zilla Slab";
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 1px;
}

.col-md-6 {
    padding: 0 50px;
}

#stage-left .stage-caption p .span-one {
    background-color: #D4F8D8;
}

#stage-left .stage-caption p .span-two {
    background-color: #FAE0D1;
}

#stage-left .stage-services {
  width: 100%;
  padding-top: 5rem;
}

#stage-left .stage-services h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}

#stage-left .stage-services .inner {
  position: absolute;
  margin-top: -3px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 25.5px;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
}

#stage-left .stage-services .inner span {
  animation: animate 12s ease 1s infinite;
  position: relative;
  color: #FE5144;
}

#stage-right {
  position: relative;
  z-index: 9;
  height: 100%;
}

#stage-right img {
    width: 100%;
    margin-bottom: 3rem;
}

#stage-right .green__cir  {
    display: inline-block;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    position: absolute;
    z-index: -1;
    top: -2rem;
    left: 10%;
    background: #aef3b4;
    /* background-image: linear-gradient(to right, #aef3b4, #dff8e2); */
    animation: motion 2s infinite alternate forwards;
}

#stage-right .red__cir  {
    display: inline-block;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    background-color: green;
    position: absolute;
    z-index: -1;
    top: 70%;
    right: 22%;
    background: #fca1a1;
    /* background-image: linear-gradient(to right, #FC7E7E, #F4C6C6); */
    animation: motion2 2s infinite alternate forwards;
}

@keyframes motion {
  from {
    transform: translateY(-7px);
  }
  to {
    transform: translateY(7px);
  }
}

@keyframes motion2 {
  from {
    transform: translateX(-7px);
  }
  to {
    transform: translateX(7px);
  }
}



/* Services */
h1, h3 {
    font-family: "Zilla Slab";
    overflow-y: hidden;
    font-weight: 500;
}

#services {
  margin-top: 16rem;
}

#services .container .service__head {
  font-family: "Zilla Slab";
  font-size: 2.3rem;
  position: relative;
  width: 160px;
  z-index: 9;
}

#services .container .service__head::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    left: -15px;
    /* background-color: rgba(102, 228, 19, 0.788); */
    background-color: rgba(0, 0, 0, 0.1);
    z-index: -1;
    transition: width 500ms 0.1s;
}

#services .container .service__head:hover::before {
  width: 100%;
}


#services .container .service__title {
    font-family: "Montserrat";
    font-size: 1.6rem;
    margin-bottom: 100px;
    color: grey;
}

#services .container .service__btn {
    margin-top: 150px;
    text-align: center;   
}

#services .container .service__btn .seeMore {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
    color: #33cc33;
    font-size: 1.1rem;
    position: relative;
    transition: background-color 100ms ease;
}

#services .container .service__btn .seeMore::after {
    content: "";
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    bottom: -5px;
    width: 0;
    background-color: #33cc33;
    transition: width 200ms cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
}

#services .container .service__btn .seeMore:hover::after {
    width: 100%;
}




/* KeyFrames  */
@keyframes animate{
  0%, 100%{
    top:0;
  }
  20%{
    top:0px;
  }
  25%{
    top:-30px;
  }
  45%{
    top:-30px;
  }
  50%{
    top:-60px;
  }
  70%{
    top:-60px;
  }
  75%{
    top: -90px;
  }
  95%{
    top: -90px;
  }
}




/* Work */



/* Media Queries */

@media screen and (max-width: 992px) {

    #stage-left .stage-caption p {
      font-size: 2rem;
    }
}

@media screen and (max-width: 768px) {

    #banner .row {
      display: flex;
      flex-direction: column-reverse;
      gap: 3rem;
      /* margin-top: inherit; */
    }

    #services .container .row .col-lg-3 { 
      padding: 15px 15px 25px ;
    }

}





@media screen and (max-width: 421px) {
    #banner {
      margin-bottom: 200px;
    }

    #banner .row {
        margin-top: 1rem;
    }

    #banner .stage-caption p {
        font-size: 1.6rem;
        line-height: 40px;
        margin-top: 2rem;
    }

    .col-md-6 {
        padding: 0 20px;
    }

    #banner .stage-services {
      padding-top: 2rem;
    }

    #stage-left .stage-services h2 {
      font-size: 1.1rem;
    }

    #stage-left .stage-services .inner {
      font-size: 1.4rem;
    }

    #banner #stage-right img {
      margin-top: 80px;
    }

    #stage-right .green__cir  {
      width: 120px;
      height: 120px;
      top: 2rem;
      left: 10%;
    }

    #stage-right .red__cir  {
      width: 80px;
      height: 80px;
    }


    /* Services */
    #services .container h1 {
      font-size: 1.9rem;
    }

   #services .container h1:hover::before {
      width: 140px;
    }
    #services .container .service__title {
      font-size: 1.2rem;
    }

    #services .container .row .col-lg-3 { 
      padding: 15px 15px 25px ;
    }

    #services .container .service__btn {
        margin-top: 70px;  
    }

}