#main__services {
    margin: 7rem 0;
    overflow-y: hidden;
}

#main__services h2 {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 2.3rem;
    font-family: "Zilla Slab";
}

#main__services .container .row:nth-of-type(2) {
    margin-top: 3rem;
    margin-bottom: 1rem;
}


#main__services .service__caption {
    background-color: #D19C8D;
    text-align: center;
    padding: 7rem 0;
    color: #fff;
    margin: 10rem 0;
}

#main__services .service__caption h4 {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;  
}

#main__services .service__caption p {
    font-size: 2rem;
    font-family: "Zilla Slab";
    margin-top: 1rem;
}

#main__services .service__values {
    background-color: #FDEFDA;
    padding: 5rem 0;
    border-top: 0.5px solid #fab348;
    border-bottom: 0.5px solid #fab348;
}



#main__services .service__values .row img {
    width: 100%;
    border-radius: 7px;
}



#main__services .service__values .row .cards h2 {
    font-size: 2rem;
    text-align: left;
}

#main__services .service__values .row .cards p:nth-of-type(1) {
    font-family: "Montserrat";
    margin-top: -2rem;
    font-size: 1.15rem;
}

#main__services .service__values .row .cards p:nth-of-type(2) {
    font-family: "Zilla Slab";
    font-size: 1.4rem;
    margin: 0;
}



/* Media Queries */
@media screen and (max-width: 768px) {

    #main__services .container .row .col-lg-3 {
        margin-bottom: 2rem;
    }

    #main__services .container .row  .cards {
        margin-top: 2rem;
    }
}