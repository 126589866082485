#process {
    margin: 7rem 0;
    z-index: 999;
}

#process .container h2 {
    text-align: center;
    margin-bottom: 5rem;
    font-family: "Zilla Slab";
    font-size: 2.3rem;
    font-weight: 400;
}

#process  .process__subhead {
    font-family: "Zilla Slab";
    font-size: 2rem;
    position: relative;
    width: 205px;
    margin-bottom: 7rem;
    z-index: 9;
    overflow-y: visible;
}   

#process  .process__subhead::before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 10px;
    left: -15px;
    background-color: #9442FD;
    opacity: 0.45;
    z-index: -1;
    transition: width 500ms 0.1s;
}

#process  .process__subhead:hover::before {
    width: 100%;
}

#process .container .row .column {
    padding-left: 1rem;
    padding-right: 1rem;
}

#process .container .row .process__card {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

#process .container .row .process__card img {
    padding: 20px;
    width: 90px;
}

#process .container .row .process__card h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1.6rem;
}

#process .container .row .process__card p {
    padding: 1.5rem;
    padding-top: 1rem;
    font-family: "Montserrat";
    font-size: 1.05rem;
}


#process .container .process__section__two {
    margin-top: 12rem;
}

#process .container .process__section__two .row__gap {
    padding-bottom: 11rem;
}

#process .container .process__section__two .row img {
    width: 100%;
}

#process .container .process__section__two .row .span {
    display: block;
    max-width: 100%;
    height: 45px;
    line-height: 45px;
    padding-left: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Montserrat";
    text-transform: uppercase;
    letter-spacing: 1px;
    
}

#process .container .process__section__two .row .span__strategy {
    background: rgba(255, 145, 77, 0.2);
    color: #e68041;
}

#process .container .process__section__two .row .span__design {
    background: rgba(134, 57, 233, 0.2);
    color: #8639E9;
}

#process .container .process__section__two .row .span__impl {
    background: rgba(236, 105, 188, 0.2);
    color: #c75a9f;
}

#process .container .process__section__two .row .process__info p {
    font-family: "Montserrat";
    font-size: 1.05rem;
}

#process .container .process__section__two .row .process__info p:nth-of-type(1) {
    font-size: 1.05rem;
    font-weight: 600;
}

#process .container .process__section__two .row .key__proc h4 {
    font-family: "Zilla Slab";
    margin: 1.2rem 0;
    margin-top: 2rem;
}

#process .container .process__section__two .row .key__proc .poceess__feat {
    display: flex;
    margin-bottom: 1rem;
}

#process .container .process__section__two .row .key__proc span {
    margin: 0 5px;
    padding: 5px 14px;
    border-radius: 3px;
    font-family: "Zilla Slab";
    font-size: 1.05rem;
}

#process .container .process__section__two .row .key__proc__strat span {
    background: rgba(255, 145, 77, 0.1);
    border: 1px solid #FF904B;
    color: #f38846;
}

#process .container .process__section__two .row .key__proc__design span {
    background: rgba(134, 57, 233, 0.1);
    border: 1px solid #8639E9;
    color: #8639E9;
}

#process .container .process__section__two .row .key__proc__impl span {
    background: rgba(236, 105, 188, 0.2);
    color: #EC69BC;
    border: 1px solid #EC69BC;
} 


#process .container .process__section__two .row .design__info .quote {
    width: 490px;
    max-width: 100%;
    position: relative;
    margin-bottom: 3rem;
}

#process .container .process__section__two .row .design__info .quote p {
    font-size: 1.15rem;
    font-weight: 600;
    
}

#process .container .process__section__two .row .design__info .float {
    position: absolute;
    right: 2rem;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.06;
    color: grey;
}



/* Media Queries */

@media screen and (max-width:992px) {
    #process .container .process__section__two .row .process__info {
        margin-top: 3rem;
    }
} 


@media screen and (max-width:769px) {
    #process .container .row .column {
        margin-bottom: 1rem;
    }
}


@media screen and (max-width:580px) {

    #process .container .process__subhead {
        margin-left: 1rem;
    }
}

@media screen and (max-width:430px) {
    #process .container .row .process__card h3 {
       font-size: 1.5rem;
    }

    #process .container .process__section__two .row .key__proc .poceess__feat {
        flex-direction: column;
        gap: 10px;
        width: fit-content;
        
    }

    #process .container .process__section__two .row .key__proc .poceess__feat span {
        margin-bottom: 4px;
    }
}