
/* Work.js */
#Work {
    /* background: #f7f7f7; */
    padding: 2rem 0;
    margin-top: 15rem;
}

#Work .row .work_header {
    /* height: 350px; */
    height: auto;
    display: grid;
    place-items: center;
    overflow: hidden;
    padding: 1rem 0;
}

#Work .row .kahaaniyaa_bg {
    background: #ABC9C4;
}

#Work .row .besttocart_bg {
    background: #CEB6ED;
}

#Work .row .work_header:hover img {
    transform: scale(1.1);
}

#Work .row .work_header img {
    width: 75%;
    margin: 0 auto;
    transition: transform 0.7s ease;
}

#Work .row .work-body {
    padding: 5px 1rem;
}

#Work .row .work-body .card-title {
    font-family: "Zilla Slab";
    margin-top: 5px;
    color: black;
}

#Work .row .work-body .work-type {
    text-transform: uppercase;
    font-family: "Montserrat";
    font-size: 0.8rem;
    letter-spacing: 1.2px;
    font-weight: 600;
    color: #d43f9e;
}

#Work .row .col-sm-6 {
    padding: 0 1.5rem;
    /* max-height: 380px !important;  */
}

#Work .work__heading {
    font-family: "Zilla Slab";
    margin-bottom: 4rem;
    z-index: 9;
    position: relative;
    margin-left: 1.3rem;
    width: 160px;
}

#Work .work__heading::before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 10px;
    left: -15px;
    background-color: #EC69BC;
    opacity: 0.45;
    z-index: -1;
    transition: width 500ms 0.1s;
}

#Work .work__heading:hover::before {
  width: 100%;
}



/* Media Queries */
@media screen and (max-width:768px) {
    #Work .row .work_header {
        /* height: 290px; */
    }

    #Work .row .col-sm-6 {
        padding: 0 0.8rem;
    }
}

@media screen and (max-width: 576px) {
    #Work .row .col-sm-6 {
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 441px) {
    #Work .row .work_header {
        /* height: 240px; */
    }
}